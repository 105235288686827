// Generated by Framer (2a172b7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["urzIwrBWe", "UoZHydkaY", "WrmvG_j3V"];

const variantClassNames = {UoZHydkaY: "framer-v-9jru08", urzIwrBWe: "framer-v-yiicpp", WrmvG_j3V: "framer-v-zxmiby"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"      ": "UoZHydkaY", "LP-Desktop-Menu-Close": "urzIwrBWe", Phone: "WrmvG_j3V"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "urzIwrBWe", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "urzIwrBWe", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0obLc", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-yiicpp", className)} data-border data-framer-name={"LP-Desktop-Menu-Close"} layoutDependency={layoutDependency} layoutId={"urzIwrBWe"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.08)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition} {...addPropertyOverrides({UoZHydkaY: {"data-framer-name": "      "}, WrmvG_j3V: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0obLc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0obLc .framer-4mqej0 { display: block; }", ".framer-0obLc .framer-yiicpp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: 22px; justify-content: center; max-width: 0px; padding: 24px 24px 24px 24px; position: relative; width: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0obLc .framer-yiicpp { gap: 0px; } .framer-0obLc .framer-yiicpp > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-0obLc .framer-yiicpp > :first-child { margin-left: 0px; } .framer-0obLc .framer-yiicpp > :last-child { margin-right: 0px; } }", ".framer-0obLc.framer-v-9jru08 .framer-yiicpp { width: 810px; }", ".framer-0obLc.framer-v-zxmiby .framer-yiicpp { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"0px",null,null]},"UoZHydkaY":{"layout":["fixed","fixed"],"constraints":[null,"0px",null,null]},"WrmvG_j3V":{"layout":["fixed","fixed"],"constraints":[null,"0px",null,null]}}}
 */
const FramerHoZOExFL5: React.ComponentType<Props> = withCSS(Component, css, "framer-0obLc") as typeof Component;
export default FramerHoZOExFL5;

FramerHoZOExFL5.displayName = "Navbar Logo Light";

FramerHoZOExFL5.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerHoZOExFL5, {variant: {options: ["urzIwrBWe", "UoZHydkaY", "WrmvG_j3V"], optionTitles: ["LP-Desktop-Menu-Close", "      ", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerHoZOExFL5, [])